import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import { Typography, Box, Container, Grid, Button } from '@mui/material';
import Footer from '../components/Footer';
import { Post } from '../types';
import axios from 'axios';

const PostPage: React.FC = () => {
  const { slug } = useParams();
  const [post, setPost] = useState<Post | null>(null);
  const [relatedPosts, setRelatedPosts] = useState<Post[]>([]);
  const [processedContent, setProcessedContent] = useState<string>('');
  const [processedRelatedPosts, setProcessedRelatedPosts] = useState<Post[]>([]);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`/api/posts/${slug}`);
        const data: Post = await response.json();
        setPost(data);

        // Increment view count and update tag priority
        await axios.post(`/api/posts/${data._id}/view`);

        const related = await fetch(`/api/posts/related/${slug}`);
        const relatedData: Post[] = await related.json();
        setRelatedPosts(relatedData);
      } catch (error) {
        console.error('Failed to fetch post:', error);
      }
    };

    fetchPost();
  }, [slug]);

  const distributeImages = (content: string, images: string[], heroImage: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const paragraphs = Array.from(doc.querySelectorAll('p'));
    const filteredImages = images.filter((image) => image !== heroImage);
    const totalParagraphs = paragraphs.length;
    const totalImages = filteredImages.length;
  
    if (totalParagraphs > 0 && totalImages > 0) {
      // Calculate the interval for even distribution
      const interval = Math.floor(totalParagraphs / totalImages);
  
      let currentImageIndex = 0;
      paragraphs.forEach((paragraph, index) => {
        if (currentImageIndex < totalImages) {
          // Calculate placement dynamically to evenly distribute images
          const isInsertPoint = (index + 1) % interval === 0 || index === totalParagraphs - 1;
          if (isInsertPoint) {
            const img = document.createElement('img');
            img.src = filteredImages[currentImageIndex];
            img.style.width = '100%';
            img.style.marginTop = '16px';
            paragraph.after(img);
            currentImageIndex++;
          }
        }
      });
    }
  
    return doc.body.innerHTML;
  };

  useEffect(() => {
    if (!post) return;

    // Process main post content
    setProcessedContent(distributeImages(post.content, post.images || [], post.heroImage));

    // Process related posts content
    const updatedRelatedPosts = relatedPosts.map((relatedPost) => ({
      ...relatedPost,
      content: distributeImages(
        relatedPost.content,
        relatedPost.images || [],
        relatedPost.heroImage
      ),
    }));

    setProcessedRelatedPosts(updatedRelatedPosts);
  }, [post, relatedPosts]);

  const adjustCardHeights = () => {
    const cardRows = document.querySelectorAll('.related-posts-row');
    cardRows.forEach((row) => {
      const cards = Array.from(row.children) as HTMLElement[];
      const heights = cards.map((card) => card.offsetHeight);
      const maxHeight = Math.max(...heights);
      cards.forEach((card) => {
        card.style.height = `${maxHeight}px`;
      });
    });
  };

  useEffect(() => {
    adjustCardHeights();
    window.addEventListener('resize', adjustCardHeights);
    return () => {
      window.removeEventListener('resize', adjustCardHeights);
    };
  }, [processedRelatedPosts]);

  if (!post) return <p>Loading...</p>;

  const [firstThreeRelated, remainingRelated] = [
    processedRelatedPosts.slice(0, 3),
    processedRelatedPosts.slice(3),
  ];

  return (
    <div
      style={{
        background: "#202020",
        color: "white",
      }}
    >
      <Header backgroundColor="rgba(20, 20, 20, 0.9)" />
      <Container>
        {/* Hero Image */}
        <Box mt="64px">
          <img
            src={post.heroImage}
            alt={post.title}
            style={{ width: '100%', maxHeight: '800px', objectFit: 'cover' }}
          />
        </Box>

        {/* Post Title */}
        <Box mt={2}>
          <Typography variant="h3" gutterBottom>
            {post.title}
          </Typography>
        </Box>

        {/* Post Metadata */}
        <Box mt={2}>
          <Typography variant="h6" color="white">
            Published on {post.createdAt ? new Date(post.createdAt).toLocaleDateString() : 'Unknown'}
          </Typography>
          {post.updatedAt && post.updatedAt !== post.createdAt && (
            <Typography variant="subtitle2" color="white">
              Updated on {new Date(post.updatedAt).toLocaleDateString()}
            </Typography>
          )}
        </Box>

        {/* Post Content */}
        <Box mt={4}>
          <div dangerouslySetInnerHTML={{ __html: processedContent }} />
        </Box>

        {/* Ad Slots */}
        <Box mt={4}>
          <div className="ad-slot">
            <script src="https://example-ad-network/ad-script.js"></script>
          </div>
        </Box>

        {/* First Three Related Posts */}
        {firstThreeRelated.map((relatedPost, index) => (
          <Box key={relatedPost._id} mt={6} mb={6}>
            <Typography variant="h5" gutterBottom>
              {index === 0
                ? "Here is a similar article you may be interested in:"
                : index === 1
                ? "Maybe this similar article may interest you:"
                : "Another article you might like:"}
            </Typography>
            <Box mt={4}>
              <img
                src={relatedPost.heroImage}
                alt={relatedPost.title}
                style={{
                  width: '100%',
                  maxHeight: '500px',
                  objectFit: 'cover',
                  borderRadius: '8px',
                }}
              />
            </Box>
            <Box mt={2}>
              <Typography variant="h4" gutterBottom>
                {relatedPost.title}
              </Typography>
              <Box mt={2}>
                <Typography variant="h6" color="white">
                  Published on{' '}
                  {relatedPost.createdAt
                    ? new Date(relatedPost.createdAt).toLocaleDateString()
                    : 'Unknown'}
                </Typography>
                {relatedPost.updatedAt && relatedPost.updatedAt !== relatedPost.createdAt && (
                  <Typography variant="subtitle2" color="white">
                    Updated on {new Date(relatedPost.updatedAt).toLocaleDateString()}
                  </Typography>
                )}
              </Box>
              <Box mt={4}>
                <div dangerouslySetInnerHTML={{ __html: relatedPost.content }} />
              </Box>
            </Box>
            {/* Ad Slots */}
            <Box mt={4}>
              <div className="ad-slot">
                <script src="https://example-ad-network/ad-script.js"></script>
              </div>
            </Box>
          </Box>
        ))}

        {/* Remaining Related Posts */}
        {remainingRelated.length > 0 && (
          <Box mt={8}>
            <Typography variant="h5" gutterBottom>
              Related Posts
            </Typography>
            <Grid container spacing={2} className="related-posts-row">
            {remainingRelated.map((relatedPost) => (
              <Grid item xs={12} sm={6} md={4} key={relatedPost._id}>
                <Box
                  style={{
                    background: "#303030",
                    borderRadius: '8px',
                    overflow: 'hidden',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <img
                    src={relatedPost.heroImage}
                    alt={relatedPost.title}
                    style={{
                      width: '100%',
                      height: '200px',
                      objectFit: 'cover',
                    }}
                  />
                  <Box className="card-content" style={{ flex: 1, padding: '16px' }}>
                    <Typography variant="h6" gutterBottom>
                      {relatedPost.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="white"
                      style={{
                        marginBottom: '8px',
                      }}
                    >
                      Published on {new Date(relatedPost.createdAt).toLocaleDateString()}
                    </Typography>
                    <Box mt={4}>
                      <div dangerouslySetInnerHTML={{ __html: relatedPost.content.slice(0, 100) }} />
                    </Box>
                    <Button
                      href={`/posts/${relatedPost.slug}`}
                      variant="contained"
                      color="primary"
                      style={{ marginTop: '8px' }}
                    >
                      Read More
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
            </Grid>
          </Box>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default PostPage;