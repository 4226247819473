import React from 'react';
import { Link } from 'react-router-dom'
import { Box, Typography } from '@mui/material';

interface Post {
  title: string;
  heroImage: string;
  category: string;
  summary: string;
  createdAt: Date | string;
  slug: string;
}

interface FeaturedPostProps {
  posts: Post[];
}

const formatDate = (date: Date | string): string => {
  const now = new Date();
  const inputDate = new Date(date);
  const diffInSeconds = Math.floor((now.getTime() - inputDate.getTime()) / 1000);

  if (diffInSeconds < 60) {
    return "Just now";
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (diffInSeconds < 604800) {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (diffInSeconds < 2592000) {
    const weeks = Math.floor(diffInSeconds / 604800);
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
  } else {
    // Format date as "MMM dd, yyyy"
    return inputDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }
};


const FeaturedPost: React.FC<FeaturedPostProps> = ({ posts }) => {
  const [mainPost, ...subPosts] = posts;
  const filteredSubPosts = subPosts.filter((post) => post.slug !== mainPost.slug);
  return (
    <Box
      className="animated-background"
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
        width: "100%",
        padding: "8rem 0 4rem",
        background: "linear-gradient(135deg, rgba(0, 0, 0, 1), rgba(0, 0, 60, 0.9))",
        backdropFilter: "blur(10px)",
        overflow: "hidden",
      }}
    >
      <Box
        className="featured-container"
        style={{
          display: "flex",
          width: "90%",
          maxWidth: "1400px",
          flexWrap: "wrap",
          gap: "1rem",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        {/* Main Post */}
        <Box
          style={{
            flex: "1 1 20%",
            background: "rgba(255, 255, 255, 0.1)",
            borderRadius: "10px",
            overflow: "hidden",
            boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.4)",
          }}
        >
          <Link to={`/posts/${mainPost.slug}`} style={{ textDecoration: 'none' }}>
            <img
              src={mainPost.heroImage}
              alt={mainPost.title}
              style={{
                aspectRatio: '16 / 9',
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            />
            <Box style={{ padding: "1rem" }}>
              <Typography 
                variant="subtitle1" 
                color="textSecondary"
                style={{
                  color: "rgb(255 255 255 / 60%)"
                }}
              >
                {formatDate(mainPost.createdAt)}
              </Typography>
              <Typography 
                variant="h3" 
                style={{ 
                  color: "white",
                  fontWeight: "700", 
                  margin: "1rem 0", 
                  fontSize: "2.5rem"
                }}
              >
                {mainPost.title}
              </Typography>
              <Typography variant="body1" style={{ color: "#aaa" }}>
                {mainPost.summary}
              </Typography>
            </Box>
          </Link>
        </Box>

        {/* Sub Posts */}
        <Box
          style={{
            flex: "1 1 0%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {filteredSubPosts.slice(0, 4).map((post, index) => (
              <Box
                key={index}
                style={{
                  display: "flex",
                  height: "139px",
                  flexGrow: "1",
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "10px",
                  overflow: "hidden",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                <Link to={`/posts/${post.slug}`} style={{ textDecoration: 'none', width: '100%', display: 'flex' }}>
                  <img
                    src={post.heroImage}
                    alt={post.title}
                    style={{
                      aspectRatio: "16 / 9",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <Box style={{ padding: "0.5rem", overflow: "hidden" }}>
                    <Typography 
                      variant="subtitle2" 
                      style={{
                        color: "rgb(255 255 255 / 60%)"
                      }}>
                      {formatDate(post.createdAt)}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: "white",
                        fontWeight: "600",
                        margin: "0.5rem 0",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "pre-wrap",
                        display: "-webkit-box",
                        WebkitLineClamp: "3",
                        WebkitBoxOrient: "vertical"
                      }}
                    >
                      {post.title}
                    </Typography>
                    <Typography variant="caption" style={{ color: "#aaa" }}>
                      {post.category}
                    </Typography>
                  </Box>
                </Link>
              </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default FeaturedPost;