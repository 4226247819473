import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

interface PostTileProps {
  title: string;
  image: string;
  category: string;
  summary: string;
  createdAt: Date | string;
  slug: string;
}

const formatDate = (date: Date | string): string => {
  const now = new Date();
  const inputDate = new Date(date);
  const diffInSeconds = Math.floor((now.getTime() - inputDate.getTime()) / 1000);

  if (diffInSeconds < 60) {
    return "Just now";
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (diffInSeconds < 604800) {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (diffInSeconds < 2592000) {
    const weeks = Math.floor(diffInSeconds / 604800);
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
  } else {
    // Format date as "MMM dd, yyyy"
    return inputDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }
};

const PostTile: React.FC<PostTileProps> = ({ title, image, category, createdAt, summary, slug }) => {
  const formattedDate = formatDate(createdAt);
  return (
    <Link to={`/posts/${slug}`} style={{ textDecoration: 'none' }}>
      <Box style={{ textAlign: 'center', overflow: 'hidden' }}>
        <img
          src={image}
          alt={title}
          style={{
            aspectRatio: '16 / 9',
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
            maxHeight: '400px',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
          }}
        />
        <Typography 
          variant="subtitle1" 
          color="textPrimary"
          style={{
            color: 'white',
            textAlign: 'left',
            padding: '.5rem 1rem 0',
          }}
          >
          {formattedDate}
        </Typography>
        <Typography
          variant="h6"
          style={{
            color: 'white',
            padding: '1rem',
            fontWeight: '700',
            fontSize: '1.2rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            height: '120px',
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 4,
            maxHeight: '5.3rem',
            lineHeight: 'unset',
          }}
        >
          {title}
        </Typography>
        {/* <Typography 
          variant="body2" 
          color="textSecondary" 
          style={{ 
            color: 'white', 
            padding: '0 1rem 1rem' 
            }}
          >
          {summary}
        </Typography> */}
        {/* <Typography variant="subtitle2" color="textSecondary">
          {category}
        </Typography> */}
      </Box>
    </Link>
  );
};

export default PostTile;