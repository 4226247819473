import React, { useContext, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import SectionsSidebar from './SectionsSidebar';
import DetailsPanel from './DetailsPanel';

const EditorOverlay = ({ children }: { children: React.ReactNode }) => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('EditorOverlay must be used within an AuthProvider');
    }
    const { isEditorMode } = context;
    const [activeTab, setActiveTab] = useState<'sections' | 'app' | 'theme'>('app');

    if (!isEditorMode) return <>{children}</>;

    return (
        <div className="editor-overlay">
            <header className="editor-top-menu">
                <button>Account</button>
                <button>Save</button>
            </header>
            <div className="editor-main">
                <SectionsSidebar activeTab={activeTab} setActiveTab={setActiveTab} />
                <main className="editor-content">{children}</main>
                <DetailsPanel activeTab={activeTab} />
            </div>
        </div>
    );
};

export default EditorOverlay;