import React from 'react';

interface SectionsSidebarProps {
    activeTab: 'sections' | 'app' | 'theme';
    setActiveTab: React.Dispatch<React.SetStateAction<'sections' | 'app' | 'theme'>>;
}

const SectionsSidebar: React.FC<SectionsSidebarProps> = ({ activeTab, setActiveTab }) => {
    return (
        <aside className="sections-sidebar">
            <button onClick={() => setActiveTab('sections')}>Sections</button>
            <button onClick={() => setActiveTab('app')}>App</button>
            <button onClick={() => setActiveTab('theme')}>Theme</button>
        </aside>
    );
};

export default SectionsSidebar;
