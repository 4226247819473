import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

const Footer: React.FC = () => (
    <Box 
        sx={{
            p: 2,
            bgcolor: "rgb(22, 22, 22)",
            color: "white",
            minHeight: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            textAlign: "center",
        }}
    >
        <Typography variant="body2">
        © {new Date().getFullYear()} Vanguard. All rights reserved.
        </Typography>
        <Typography variant="body2">
            <Link to="/">Home</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
        </Typography>
    </Box>
);

export default Footer;