import React from 'react';

const DetailsPanel = ({ activeTab }: { activeTab: string }) => {
    return (
        <aside className="details-panel">
            {activeTab === 'sections' && <div>Section Details</div>}
            {activeTab === 'app' && <div>App Details</div>}
            {activeTab === 'theme' && <div>Theme Details</div>}
        </aside>
    );
};

export default DetailsPanel;