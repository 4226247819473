import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PostTile from './PostTile';
import { Grid, Box, Button, Typography } from '@mui/material';

// Define the type for a tag object
interface Tag {
    name: string;
    priority: number;
}

// Define the type for a post object
interface Post {
    _id: string;
    title: string;
    content: string;
    heroImage: string;
    createdAt: string;
    slug: string;
    isDraft: boolean;
}

// Excluded tags (define outside the component to avoid dependency issues)
const excludedTags = ['gaming', 'trending', 'featured'];

// Define the props for the TagNavbar component
interface TagNavbarProps {
    onTagClick: (tagName: string) => void;
}

const TagNavbar: React.FC<TagNavbarProps> = ({ onTagClick }) => {
    const [tags, setTags] = useState<Tag[]>([]);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axios.get<Tag[]>('/api/posts/tags/popular');
                const filteredTags = response.data.filter(tag => !excludedTags.includes(tag.name));
                setTags(filteredTags);
            } catch (error) {
                console.error('Failed to fetch tags:', error);
            }
        };

        fetchTags();
    }, []);

    return (
        <Box
            className="tag-navbar"
            style={{
                display: 'flex',
                overflowX: 'scroll',
                whiteSpace: 'nowrap',
                padding: '10px 0',
                backgroundColor: 'rgb(20 20 20)',
                width: '100%',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
                justifyContent: 'center',
            }}
        >
            {/* Hide scrollbar for Webkit-based browsers */}
            <style>
                {`
                .tag-navbar::-webkit-scrollbar {
                    display: none;
                }
                `}
            </style>
            {tags.map((tag) => (
                <button
                    className="tagItem"
                    key={tag.name}
                    onClick={() => onTagClick(tag.name)}
                    style={{
                        flex: '0 0 auto',
                        margin: '0 8px',
                        padding: '8px 16px',
                        backgroundColor: 'rgb(30 30 30)',
                        fontSize: '1rem',
                        fontWeight: '600',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                    }}
                >
                    {tag.name}
                </button>
            ))}
        </Box>
    );
};

const TagPostModule: React.FC = () => {
    const [selectedTag, setSelectedTag] = useState<string>('news');
    const [posts, setPosts] = useState<Post[]>([]);
    const [visiblePosts, setVisiblePosts] = useState<number>(10);

    // Update visible posts based on the screen size
    useEffect(() => {
        const updateVisiblePosts = () => {
            if (window.innerWidth >= 960) {
                setVisiblePosts(9); // 33% (3 columns)
            } else if (window.innerWidth >= 600) {
                setVisiblePosts(8); // 50% (2 columns)
            } else {
                setVisiblePosts(10); // 100% (1 column)
            }
        };

        updateVisiblePosts();
        window.addEventListener('resize', updateVisiblePosts);

        return () => {
            window.removeEventListener('resize', updateVisiblePosts);
        };
    }, []);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get<Post[]>(`/api/posts?tag=${selectedTag}`);
                // Filter out posts with isDraft set to true
                const filteredPosts = response.data.filter(post => !post.isDraft);

                // Update state with the filtered posts
                setPosts(filteredPosts);
            } catch (error) {
                console.error('Failed to fetch posts:', error);
            }
        };

        fetchPosts();
    }, [selectedTag]);

    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                background: '#101010',
            }}
        >
            <Typography 
                variant="h4"
                style={{ 
                    width: '100%',
                    backgroundColor: '#141414',
                    margin: '0',
                    padding: "1.8rem 0",
                    color: "white", 
                    fontSize: "1.8rem",
                    textAlign: "center", 
                    fontFamily: "Montserrat, Helvetica, Arial, sans-serif", 
                    fontWeight: "700" 
                }} 
                gutterBottom
            >
            Choose Popular Tags:
            </Typography>
            
            {/* Navbar for Tags */}
            <TagNavbar onTagClick={setSelectedTag} />

            {/* Display Posts */}
            <Box
                style={{
                    maxWidth: '1200px',
                    padding: '16px',
                }}
            >
                <Grid container spacing={2}>
                    {posts.slice(0, visiblePosts).map((post) => (
                        <Grid item xs={12} sm={6} md={4} key={post._id} className="grid-item-container" style={{ padding: '0 !important' }}>
                            <Box
                                className="post-tile-container"
                                style={{
                                    flex: '0 0 auto',
                                    transform: 'scale(0.9)',
                                    backgroundColor: '#202020',
                                    borderRadius: '8px',
                                }}
                            >
                                <PostTile
                                    title={post.title}
                                    image={post.heroImage}
                                    category={selectedTag}
                                    createdAt={post.createdAt}
                                    summary={post.content.slice(0, 100)}
                                    slug={post.slug}
                                />
                            </Box>
                        </Grid>
                    ))}
                </Grid>

                {/* "See More" Button */}
                {posts.length > visiblePosts && (
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '16px',
                        }}
                    >
                        <Button
                            onClick={() => setVisiblePosts((prev) => prev + 10)}
                            variant="contained"
                            style={{
                                color: 'white',
                                background: '#303030',
                            }}
                        >
                            See More
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default TagPostModule;
