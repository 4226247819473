import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import Slider from "react-slick";
import PostTile from "./PostTile";

interface TrendingSliderProps {
  trendingPosts: Array<{
    title: string;
    image: string;
    category: string;
    summary: string;
    createdAt: Date;
    slug: string;
    tags?: string[];
  }>;
}

const TrendingSlider: React.FC<TrendingSliderProps> = ({ trendingPosts }) => {
  // Sort trending posts
  const sortedPosts = trendingPosts
    .sort((a, b) => {
      const aIsTrending = a.tags?.includes("trending") ? 1 : 0;
      const bIsTrending = b.tags?.includes("trending") ? 1 : 0;
      return bIsTrending - aIsTrending;
    })
    .slice(0, 10);

  const sliderRef = useRef<Slider>(null);

  // Slider settings
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    swipe: true,
    touceMove: true,
    responsive: [
      {
        breakpoint: 1440, // Large screens (e.g., laptops)
        settings: {
          slidesToShow: 4, 
        },
      },
      {
        breakpoint: 1024, // Tablets in landscape mode
        settings: {
          slidesToShow: 3, 
        },
      },
      {
        breakpoint: 768, // Tablets in portrait mode
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480, // Small mobile screens
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    let startX = 0;

    const handleTouchStart = (event: TouchEvent) => {
      startX = event.touches[0].clientX;
    };

    const handleTouchMove = (event: TouchEvent) => {
      const currentX = event.touches[0].clientX;
      const deltaX = currentX - startX;

      if (Math.abs(deltaX) > 50) { 
        if (deltaX > 0 && sliderRef.current) {
          sliderRef.current.slickPrev(); 
        } else if (deltaX < 0 && sliderRef.current) {
          sliderRef.current.slickNext(); 
        }
        startX = currentX; 
      }
    };

    const sliderContainer = document.querySelector(".slick-slider");
    if (sliderContainer) {
      sliderContainer.addEventListener("touchstart", handleTouchStart as EventListener);
      sliderContainer.addEventListener("touchmove", handleTouchMove as EventListener);
    }

    return () => {
      if (sliderContainer) {
        sliderContainer.removeEventListener("touchstart", handleTouchStart as EventListener);
        sliderContainer.removeEventListener("touchmove", handleTouchMove as EventListener);
      }
    };
  }, []);
  

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
      if (sliderRef.current) {
        if (event.deltaY < 0) {
          sliderRef.current.slickPrev();
        } else {
          sliderRef.current.slickNext();
        }
      }
    };

    const sliderContainer = document.querySelector(".slick-slider");
    if (sliderContainer) {
      sliderContainer.addEventListener("wheel", handleWheel as EventListener);
    }

    return () => {
      if (sliderContainer) {
        sliderContainer.removeEventListener("wheel", handleWheel as EventListener);
      }
    };
  }, []);

  return (
    <Box style={{ width: "100%", overflow: "hidden", backgroundColor: "#121212", position: "relative" }}>
      <Box className="trending-slider" style={{ width: "90%", maxWidth: "1200px", margin: "0 auto", padding: "2rem 0" }}>
        <Typography 
          variant="h4"
          style={{ 
            color: "white", 
            textAlign: "center", 
            fontFamily: "Montserrat, Helvetica, Arial, sans-serif", 
            fontWeight: "700" 
          }} 
          gutterBottom
        >
          Trending Stories
        </Typography>
        <Typography
          variant="subtitle1"
          component="h2"
          style={{ color: "white", textAlign: "center", padding: "0 0 1rem" }}
        >
          Featuring the top 10 most trending stories
        </Typography>
        <Slider {...settings} ref={sliderRef}>
          {sortedPosts.length > 0 ? (
            sortedPosts.map((post, index) => (
              <Box
                className="trending-item"
                key={index}
                style={{
                  flex: '0 0 auto',
                  width: '400px',
                  backgroundColor: '#202020',
                  borderRadius: '8px',
                }}
              >
                <PostTile
                  title={post.title}
                  image={post.image}
                  category={post.category}
                  createdAt={post.createdAt}
                  summary={post.summary}
                  slug={post.slug}
                />
              </Box>
            ))
          ) : (
            <Typography>No trending posts available</Typography>
          )}
        </Slider>
      </Box>
    </Box>
  );
};

export default TrendingSlider;
