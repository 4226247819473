import React, { useEffect, useState } from "react";
import EditorOverlay from '../components/EditorOverlay';
import Header from "../components/Header";
import FeaturedPost from "../components/FeaturedPost";
import TagPostModule from "../components/TagPostModule";
import TrendingSlider from "../components/TrendingSlider";
import Footer from "../components/Footer";
import { Post } from "../types";
import axios from "axios";

const Homepage: React.FC = () => {
  const [featuredPosts, setFeaturedPosts] = useState<Post[]>([]);
  const [trendingPosts, setTrendingPosts] = useState<Post[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchHomepageContent = async () => {
      try {
        const featuredResponse = await axios.get("/api/posts/featured");
        const trendingResponse = await axios.get("/api/posts/trending");

        // Combine featured post(s) and trending posts
        const featuredPost = featuredResponse.data;
        const subPosts = trendingResponse.data.slice(0, 5);
        setFeaturedPosts([featuredPost, ...subPosts]);

        setTrendingPosts(trendingResponse.data);
      } catch (error: any) {
        console.error("Failed to fetch homepage content:", error.message);
        setError(error.message);
      }
    };

    fetchHomepageContent();
  }, []);

  return (
    <div>
      <EditorOverlay>
        <Header />
        {error && <div>Error: {error}</div>}

        {featuredPosts.length > 0 ? (
          <FeaturedPost posts={featuredPosts} />
        ) : (
          <p>No featured posts available.</p>
        )}

        <TagPostModule />

        {trendingPosts.length > 0 ? (
          <TrendingSlider
            trendingPosts={trendingPosts.map((post) => ({
              title: post.title,
              image: post.heroImage,
              category: post.category,
              summary: post.summary,
              createdAt: post.createdAt,
              slug: post.slug,
            }))}
          />
        ) : (
          <p>No trending posts available.</p>
        )}

        <Footer />
      </EditorOverlay>
    </div>
  );
};

export default Homepage;
