import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Chip,
  Slider,
} from '@mui/material';
import api from '../services/api';
import { Post } from '../types';
import { toast } from 'react-toastify';
import RichTextEditor from '../components/RichTextEditor';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import 'react-quill-new/dist/quill.snow.css';

const PostReview: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tags, setTags] = useState<string[]>([]);
  const [newTag, setNewTag] = useState<string>('');
  const [images, setImages] = useState<string[]>([]);
  const [heroImage, setHeroImage] = useState<string>('');
  const [originalImages, setOriginalImages] = useState<string[]>([]);
  const [originalHeroImage, setOriginalHeroImage] = useState<string>("");
  const [deletedImages, setDeletedImages] = useState<string[]>([]);

  // Fetch all posts
  const fetchAllPosts = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get<Post[]>(`/posts/all?timestamp=${new Date().getTime()}`);
      setPosts(response.data || []);
    } catch (error) {
      console.error('Error fetching posts:', error);
      toast.error('Failed to load posts');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAllPosts();
  }, [fetchAllPosts]);

  // Handle post selection
  const handleSelectPost = (post: Post) => {
    setSelectedPost(post);
    setTags(post.tags || []);
    setImages(post.images || []);
    setHeroImage(post.heroImage || '');
    setOriginalImages(post.images || []);
    setOriginalHeroImage(post.heroImage || "");
    setDeletedImages([])
    setIsModalOpen(true);
  };

  // Reorder images
  const handleReorderImages = (newOrder: string[]) => {
    const newHeroImage = newOrder[0];
    const remainingImages = newOrder.slice(1);
  
    // Ensure the previous hero image is added back if not deleted
    if (heroImage && !remainingImages.includes(heroImage) && heroImage !== newHeroImage) {
      remainingImages.push(heroImage);
    }
  
    // Deduplicate images
    const uniqueImages = Array.from(new Set([newHeroImage, ...remainingImages]));
  
    setHeroImage(newHeroImage);
    setImages(uniqueImages);
  
    if (selectedPost) {
      setSelectedPost({
        ...selectedPost,
        heroImage: newHeroImage,
        images: uniqueImages,
      });
    }
  }

  // Upload a new image
  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      console.log("No file selected.");
      return;
    }
  
    const file = event.target.files[0];
    console.log("Selected file:", {
      name: file.name,
      size: file.size,
      type: file.type,
    });
  
    // Validate file type and size
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      toast.error('Only JPG and PNG files are allowed.');
      console.log("Invalid file type:", file.type);
      return;
    }
    if (file.size > 20 * 1024 * 1024) {
      toast.error('File size must not exceed 20MB.');
      console.log("File size too large:", file.size);
      return;
    }
  
    // Prepare form data
    const formData = new FormData();
    formData.append('image', file);
    console.log("FormData prepared for upload:", formData);
  
    try {
      console.log("Starting image upload...");
      const { data } = await api.post('/posts/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
  
      console.log("Upload response:", data);
  
      if (!data.filePath) {
        toast.error('Failed to upload image.');
        console.log("File path not returned in response.");
        return;
      }
  
      const updatedImages = [...images, data.filePath];
      const uniqueImages = Array.from(new Set(updatedImages));

      setImages(uniqueImages);
      if (uniqueImages.length === 1) {
        setHeroImage(data.filePath);
      }

      if (selectedPost) {
        setSelectedPost({
          ...selectedPost,
          images: uniqueImages,
          heroImage: uniqueImages[0],
        });
      }
  
      toast.success('Image uploaded successfully!');
    } catch (error: any) {
      console.error("Image upload failed:", error);
      console.log("Error details:", error.response || error.message || error);
      toast.error(`Failed to upload image: ${error.message}`);
    }
  };  

  // Save post as a draft
  const handleSavePost = async () => {
    if (!selectedPost) return;
  
    try {
      // Reconcile images to ensure no missing images
      const reconciledImages = Array.from(
        new Set([...originalImages, ...images, originalHeroImage].filter(Boolean))
      );
  
      await api.put(`/posts/${selectedPost._id}`, {
        ...selectedPost,
        summary: selectedPost.summary,
        slug: selectedPost.slug,
        tags,
        heroImage,
        images: reconciledImages,
        deletedImages,
        isDraft: true,
      });
      setDeletedImages([]);

      toast.success('Post saved as a draft!');
      setIsModalOpen(false);
      fetchAllPosts();
    } catch (error) {
      console.error('Error saving post:', error);
      toast.error('Failed to save post.');
    }
  };
  
  const handlePublishPost = async () => {
    if (!selectedPost) return;
  
    try {
      const reconciledImages = Array.from(
        new Set([...originalImages, ...images, originalHeroImage].filter(Boolean))
      );
  
      await api.put(`/posts/${selectedPost._id}`, {
        ...selectedPost,
        summary: selectedPost.summary,
        slug: selectedPost.slug,
        tags,
        heroImage,
        images: reconciledImages,
        deletedImages,
        isDraft: false,
      });
      setDeletedImages([]);
      
      toast.success('Post published successfully!');
      setIsModalOpen(false);
      fetchAllPosts();
    } catch (error) {
      console.error('Error publishing post:', error);
      toast.error('Failed to publish post.');
    }
  };
  
  // Delete a post
  const handleDeletePost = async (postId: string) => {
    try {
      await api.delete(`/posts/${postId}`);
      toast.success('Post deleted successfully!');
      fetchAllPosts();
    } catch (error) {
      console.error('Error deleting post:', error);
      toast.error('Failed to delete post.');
    }
  };

  // Add a new tag
  const handleAddTag = () => {
    if (newTag && !tags.includes(newTag)) {
      setTags([...tags, newTag]);
      setNewTag('');
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box maxWidth="1000px" style={{ width: '50%', margin:'auto', padding: '2rem' }}>
      <Typography variant="h5" style={{ color: 'white', textAlign: 'center', fontSize: '2rem', padding: '0 2rem 0.9rem 2rem' }}>Pending Post Review</Typography>
      {posts.length > 0 ? (
        posts.map((post) => (
          <Box key={post._id} my={2} p={2} border="1px solid #ccc" borderRadius="12px" display="flex" sx={{ background: '#181818' }}>
            <img
              src={post.heroImage}
              alt={`Article img`}
              style={{ flex: '20%', width: '100%', height: '200px', aspectRatio: '16 / 9', objectFit: 'cover', borderRadius: '12px' }}
            />
            <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ flex: "80%" }} >
              <Box>
                <Typography variant="h6" style={{ color: 'white', marginLeft: '16px' }}>{post.title}</Typography>
                <Typography variant="body2" style={{ color: '#ccc', marginTop: '0.5rem', marginLeft: '16px' }}>
                  Status: {post.isDraft ? 'Draft' : 'Published'}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                >
                <Button onClick={() => handleSelectPost(post)}>Review Post</Button>
                <Button onClick={() => handleDeletePost(post._id)} style={{color: 'red'}}>
                  Delete Post
                </Button>
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <Typography>No posts available for review.</Typography>
      )}

      {selectedPost && (
        <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth="md" fullWidth>
          <DialogTitle>Editing Post: {selectedPost.title}</DialogTitle>
          <DialogContent>
            <Box mt={2}>
              <TextField
                fullWidth
                label="Title"
                value={selectedPost.title}
                onChange={(e) =>
                  setSelectedPost({ ...selectedPost, title: e.target.value })
                }
              />
              <TextField
                fullWidth
                label="Author"
                value={selectedPost.author}
                onChange={(e) =>
                  setSelectedPost({ ...selectedPost, author: e.target.value })
                }
                style={{ marginTop: '16px' }}
              />
              <TextField
                fullWidth
                label="Category"
                value={selectedPost.category}
                onChange={(e) =>
                  setSelectedPost({ ...selectedPost, category: e.target.value })
                }
                style={{ marginTop: '16px' }}
              />
              <Typography variant="body1" style={{ marginTop: '0.5em', marginLeft: '13px' }}>
                Tags:
              </Typography>
              <Box sx={{ display: 'flex', margin: '.5rem 0rem 1rem 0rem' }}>
                <TextField
                  style={{ marginLeft: '0px' }}
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  label="New Tag"
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleAddTag();
                    }
                  }}
                />
                <Button onClick={handleAddTag}>Add Tag</Button>
              </Box>
              <Box display="flex" alignItems="center" flexWrap="wrap" style={{ marginTop: '16px' }}>
                {tags.map((tag, index) => (
                  <Chip
                    style={{
                      marginRight: '5px',
                      marginBottom: '5px'
                    }}
                    key={index}
                    label={tag}
                    onDelete={() => setTags(tags.filter((t) => t !== tag))}
                  />
                ))}
              </Box>

              <Typography variant="body1" style={{ marginTop: '16px' }}>
                Upload Image:
              </Typography>
              <input type="file" accept="image/*" onChange={handleImageUpload} />
              <Typography variant="body1" style={{ marginTop: '16px' }}>
                Reorder Images:
              </Typography>
              <ImageReorder
                images={[heroImage, ...images.filter((img) => img !== heroImage)]}
                heroImage={heroImage}
                setHeroImage={setHeroImage}
                onReorder={handleReorderImages}
                setDeletedImages={setDeletedImages}
              />

              <TextField
                fullWidth
                label="Summary"
                value={selectedPost?.summary || ''}
                onChange={(e) => setSelectedPost({ ...selectedPost, summary: e.target.value })}
                style={{ marginTop: '16px' }}
                multiline
                rows={3}
              />

              <Typography variant="body1" style={{ marginTop: '16px' }}>
                Content:
              </Typography>
              <RichTextEditor
                initialValue={selectedPost.content}
                onChange={(content) =>
                  setSelectedPost({ ...selectedPost, content })
                }
                postId={selectedPost._id}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={4}>
              <Button
                onClick={handleSavePost}
                variant="contained"
                color="secondary"
                style={{ marginRight: '16px' }}
              >
                Save Changes
              </Button>
              <Button onClick={handlePublishPost} variant="contained" color="primary">
                Publish Post
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

const ImageReorder = ({ 
  images, 
  heroImage,
  setHeroImage,
  onReorder,
  setDeletedImages,
}: { 
  images: string[]; 
  heroImage: string;
  setHeroImage: (newOrder: string) => void;
  onReorder: (newOrder: string[]) => void ;
  setDeletedImages: (callback: (prev: string[]) => string[]) => void;
}) => {
  const handleDelete = (index: number) => {
    const imageToDelete = images[index];
    const updatedImages = images.filter((_, i) => i !== index);

    // If the hero image is deleted, update the hero image
    if (imageToDelete === heroImage) {
      const newHeroImage = updatedImages.length > 0 ? updatedImages[0] : ''; // Fallback to empty if no images remain
      setHeroImage(newHeroImage);
    }

    // Add the deleted image to the deletedImages list
    setDeletedImages((prev) => [...prev, imageToDelete]);

    onReorder(updatedImages);
  };

  const moveImage = (fromIndex: number, toIndex: number) => {
    const updated = [...images];
    const [moved] = updated.splice(fromIndex, 1);
    updated.splice(toIndex, 0, moved);
    onReorder(updated);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      {images.map((image, index) => (
        <DraggableImage 
          key={image} 
          index={index} 
          image={image} 
          isHero={index === 0}
          moveImage={moveImage} 
          onDelete={handleDelete} 
        />
      ))}
    </DndProvider>
  );
};

const DraggableImage = ({
  image,
  index,
  moveImage,
  onDelete,
  isHero,
}: {
  image: string;
  index: number;
  moveImage: (fromIndex: number, toIndex: number) => void;
  onDelete: (index: number) => void;
  isHero?: boolean;
}) => {
  const [, ref] = useDrop({
    accept: 'image',
    hover: (dragged: { index: number }) => {
      if (dragged.index !== index) {
        moveImage(dragged.index, index);
        dragged.index = index;
      }
    },
  });

  const [, drag] = useDrag({
    type: 'image',
    item: { index },
  });

  return (
    <div
      ref={(node) => drag(ref(node))}
      style={{
        margin: '5px',
        display: 'inline-block',
        position: 'relative',
        border: isHero ? '2px solid gold' : 'none',
        borderRadius: '5px',
      }}
    >
      <img
        src={image}
        alt="post"
        style={{ width: '200px', borderRadius: '5px', cursor: 'pointer' }}
      />
      {isHero && (
        <Typography
          style={{
            position: 'absolute',
            top: '5px',
            left: '5px',
            background: 'rgba(0, 0, 0, 0.6)',
            color: 'gold',
            padding: '2px 5px',
            borderRadius: '3px',
          }}
        >
          Hero Image
        </Typography>
      )}
      <button
        className="image-delete"
        onClick={() => onDelete(index)}
        style={{
          position: 'absolute',
          top: '5px',
          right: '5px',
          background: 'red',
          color: 'white',
          border: 'none',
          borderRadius: '50%',
          cursor: 'pointer',
        }}
      >
        ✕
      </button>
    </div>
  );
};


export default PostReview;