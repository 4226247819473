import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const AdminSignupRequest: React.FC = () => {
  const [email, setEmail] = useState('');

  const handleRequestSignup = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/requestSignup`, { email });
      toast('Signup request submitted. Await approval.');
    } catch (error) {
      toast('Failed to submit signup request.');
    }
  };

  return (
    <Box
      height="100vh"
      display="flex"
      >
      <Box
        width="20%"
        margin="auto"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        color="#ccc"
        padding="1rem"
        borderRadius="12px"
        >
        <Typography variant="h4" gutterBottom>Request Signup Access</Typography>
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleRequestSignup} style={{ marginTop: '16px' }}>
          Request Access
        </Button>
      </Box>
    </Box>
  );
};

export default AdminSignupRequest;
