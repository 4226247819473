import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

interface HeaderProps {
  backgroundColor?: string;
}

const Header: React.FC<HeaderProps> = ({ backgroundColor }) => {
  const [lastScrollY, setLastScrollY] = useState(0);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY && currentScrollY > 50) {
        setHidden(true);
      } else if (currentScrollY < lastScrollY) {
        setHidden(false);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  return (
    <AppBar 
      position="static" 
      style={{
        position: "fixed",
        top: hidden ? "-70px" : "0",
        zIndex: "9",
        backgroundColor: backgroundColor || "rgba(20, 20, 20, 0.5)",
        backdropFilter: "blur(10px)",
        transition: "top 0.3s ease-in-out",
      }}
      >
      <Toolbar>
        <Typography variant="h6" component={Link} to="/" style={{ textDecoration: 'none', color: 'white', fontFamily: 'Montserrat, Helvetica, Arial, sans-serif', fontWeight: '700' }}>
          Vanguard
        </Typography>
        <Box ml={2}>
          <Button color="inherit" component={Link} to="/category/sports">
            Sports
          </Button>
          <Button color="inherit" component={Link} to="/category/entertainment">
            Entertainment
          </Button>
          <Button color="inherit" component={Link} to="/category/technology">
            Technology
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;