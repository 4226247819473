import React, { createContext, useState, useEffect, ReactNode } from 'react';
import api from '../services/api';
import { User } from '../types';
import { toast } from 'react-toastify';

interface AuthContextProps {
  user: User | null;
  isAuthenticated: boolean;
  loading: boolean;
  isEditorMode: boolean;
  setEditorMode: (mode: boolean) => void;
  login: (email: string, password: string) => Promise<void>;
  signup: (username: string, email: string, password: string) => Promise<void>;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isEditorMode, setIsEditorMode] = useState<boolean>(false);

  useEffect(() => {
    const checkAuth = async () => {
      setLoading(true);
      try {
        const response = await api.get<User>('/auth/me', { withCredentials: true });
        setUser(response.data);
      } catch (error) {
        setUser(null);
        console.error('Authentication check failed:', error);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      await api.post('/auth/login', { email, password }, { withCredentials: true });
      const response = await api.get<User>('/auth/me', { withCredentials: true });
      setUser(response.data);
      toast.success('Logged in successfully!');
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to login.');
      console.error('Login error:', error);
      throw error;
    }
  };

  const signup = async (username: string, email: string, password: string) => {
    try {
      await api.post<{ message: string }>('/auth/signup', { username, email, password });
      toast.success('Signed up successfully!');
      await login(email, password);
    } catch (error: any) {
      console.error('Signup error:', error);
      toast.error(error.response?.data?.message || 'Failed to signup.');
      throw error;
    }
  };

  const logout = async () => {
    try {
      await api.post('/auth/logout', {}, { withCredentials: true });
      setUser(null);
      setIsEditorMode(false);
      toast.success('Logged out successfully!');
    } catch (error) {
      console.error('Logout error:', error);
      toast.error('Failed to logout.');
    }
  };

  return (
    <AuthContext.Provider 
      value={{ 
        user, 
        isAuthenticated: !!user, 
        loading, 
        isEditorMode, 
        setEditorMode: setIsEditorMode, 
        login, 
        signup, 
        logout 
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
